import $ from 'jquery';
import prestashop from 'prestashop';

/**
 * Update address form on country change
 * Emit "addressFormUpdated" event
 *
 * @param selectors
 */
function handleCountryChange (selectors) {
  $('body').on('change', selectors.country, () => {
    var requestData = {
      id_country: $(selectors.country).val(),
      id_address: $(selectors.address + ' form').data('id-address'),
    };
    var getFormViewUrl = $(selectors.address + ' form').data('refresh-url');
    var formFieldsSelector = selectors.address + ' input';

    $.post(getFormViewUrl, requestData).then(function (resp) {
      var inputs = [];

      // Store fields values before updating form
      $(formFieldsSelector).each(function () {
        inputs[$(this).prop('name')] = $(this).val();
      });

      $(selectors.address).replaceWith(resp.address_form);

      // Restore fields values
      $(formFieldsSelector).each(function () {
        $(this).val(inputs[$(this).prop('name')]);
      });

      prestashop.emit('updatedAddressForm', {target: $(selectors.address), resp: resp});
    }).fail((resp) => {
      prestashop.emit('handleError', {eventType: 'updateAddressForm', resp: resp});
    });
  });
}

var strRM = "";
var idsRM = [];
var arrRegion = [];
var arrComunas = [];
var selectname = '.js-address-form select[name=city]';
var selectcomunas = '.js-address-form select[name=id_state]';
var __currentRegion;
// INIT !!!!
function setSelectedDK(){
  idsRM = [];
  arrRegion = [];
  arrComunas = [];

  __currentRegion = $(selectname).val();
  var arraycitys = $(selectname).children('option');

  for (let i = 0; i < arraycitys.length; i++) {
    const elementcity = arraycitys[i];
    var _value = $(elementcity).attr('value');
    var _disabled = $(elementcity).attr('disabled');
    var _selected = $(elementcity).attr('selected');
    var _name = $(elementcity).html();
    var _arrname = _name.split('RM_');
    var _namecity = _name;
    var _nosi = true;

    if(_value == 13){
      strRM = _value;
    }
    
    if(_arrname[0] == '_'){
      __currentRegion = 13;
      _namecity = _arrname[1];
      idsRM.push(_value);
      _nosi = false;
    }

    if(_selected == 'selected'){
      _selected = 'selected';
    }else{
      _selected = '';
    }
    
    if(_disabled == 'disabled'){
      _disabled = 'disabled';
    }else{
      _disabled = '';
    }

    arrRegion.push({
      value: _value, 
      disabled: _disabled,
      html: _name,
      nosi: _nosi,
      selected: _selected
    });
  }
  //console.log(arrRegion);

  var array = $(selectcomunas).children('option');

  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    var _value = $(element).attr('value');
    var _disabled = $(element).attr('disabled');
    var _selected = $(element).attr('selected');
    var _name = $(element).html();
    var _arrname = _name.split('_');
    var _namecomuna = _arrname[0];
    var _id_zone = _arrname[1];

    if(_selected == 'selected'){
      _selected = 'selected';
    }else{
      _selected = '';
    }
    
    if(_disabled == 'disabled'){
      _disabled = 'disabled';
    }else{
      _disabled = '';
    }

    arrComunas.push({
      value: _value, 
      disabled: _disabled,
      html: _name,
      name: _namecomuna,
      id_zone: _id_zone,
      selected: _selected
    });
  }

  renderSelectRegion();
  renderSelectComuna();

  
  $('body').on('change', selectname, () => {
    resetComuna();
    renderSelectComuna();
  });
}

function resetComuna(){
  console.log('resetComuna');
  var currentRegion = $(selectname).val();

  for (let i = 0; i < arrComunas.length; i++) {
    const element = arrComunas[i];

    if(element.disabled == 'disabled' || element.value == ''){
      //
    }else if(element.id_zone == currentRegion){
      arrComunas[i].selected = '';
    }
  }
}

function renderSelectComuna(){
  var strnewoption = '';

  for (let i = 0; i < arrComunas.length; i++) {
    const element = arrComunas[i];
    
    if(element.disabled == 'disabled' || element.value == ''){
      strnewoption += '<option value="" disabled '+element.selected+'>'+element.html+'</option>';
    }else if(getIsIdZone(element)){
      strnewoption += '<option value="'+element.value+'" '+element.disabled+' '+element.selected+'>'+element.name+'</option>';
    }
  }
  $(selectcomunas).html(strnewoption);
}

function getIsIdZone(_element){
  var id_zone = _element.id_zone;
  var currentRegion = $(selectname).val();
  var nono = false;
  
  if(id_zone == currentRegion){
    nono = true;
  }else if(currentRegion == 13){
    for (let i = 0; i < idsRM.length; i++) {
      const element = idsRM[i];
      if(element == id_zone){
        nono = true;
        break;
      }
    }
  }

  return nono;
}

function renderSelectRegion(){
  var strnewoption = '';

  for (let i = 0; i < arrRegion.length; i++) {
    const element = arrRegion[i];
    
    if(element.disabled == 'disabled' || element.value == ''){
      strnewoption += '<option value="" disabled '+element.selected+'>'+element.html+'</option>';
    }else if(element.nosi){
      var fixsele = '';
      if(__currentRegion == element.value){
        fixsele = 'selected';
      }
      strnewoption += '<option value="'+element.value+'" '+element.disabled+' '+fixsele+'>'+element.html+'</option>';
    }
  }
  $(selectname).html(strnewoption);
}

$(document).ready(() => {
  handleCountryChange({
    'country': '.js-country',
    'address': '.js-address-form'
  });

  if($(selectname).length > 0){
    setSelectedDK();
  }
});
